@use "~@angular/material" as mat;

* {
  font-family: "Poppins", sans-serif;
  line-height: 1.7;
}

h1 {
  font: 500 25px/40px "Poppins", sans-serif;
}

@include mat.core();

// 3c90df
/* For use in src/lib/core/theming/_palette.scss */
$seconize-blue: (
  50: #e8f2fb,
  100: #c5def5,
  200: #9ec8ef,
  300: #77b1e9,
  400: #59a1e4,
  500: #3c90df,
  600: #3688db,
  700: #2e7dd7,
  800: #2773d2,
  900: #1a61ca,
  A100: #fefeff,
  A200: #cbdeff,
  A400: #98beff,
  A700: #7faeff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// LIGHT THEME START
$light-primary: mat.define-palette($seconize-blue, 500, 100, 900);
$light-accent: mat.define-palette(mat.$blue-gray-palette);
$light-warn: mat.define-palette(mat.$red-palette);
$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $light-primary,
      accent: $light-accent,
      warn: $light-warn,
    ),
  )
);

// include theme in all component
@include mat.all-component-themes($light-theme);

// LIGHT THEME END

// DARK THEME START
// $dark-primary: mat.define-palette($seconize-blue, 500, 100, 900);
// $dark-accent: mat.define-palette(mat.$blue-gray-palette);
// $dark-theme: mat.define-dark-theme(
//   (
//     color: (
//       primary: $dark-primary,
//       accent: $dark-accent,
//     ),
//   )
// );

// .darkMode {
//   @include mat.all-component-colors($dark-theme);
// }

// DARK THEME END

*,
.mat-typography h1 {
  font-family: "Poppins", sans-serif;
}

html,
body {
  background-color: rgba(125, 125, 125, 0.076);
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

//card styling here
.mat-card {
  transition: 200ms ease-in;
  padding: 9px !important;
}

//themed shadow and border
.seconizeShadow {
  box-shadow: none !important;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px !important;
  border-radius: 20px !important;
}

.seconizeShadowBottom {
  box-shadow: none !important;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px !important;
  border-radius: 0 0 20px 20px !important;
}

.customPaginatorWrapper {
  height: 3.2rem;

  .mat-select-value {
    transform: translateY(-3px);
  }

  .mat-form-field-wrapper {
    padding: 0 !important;
  }
}

.mat-form-field-wrapper {
  padding-bottom: 0;
}

//  navbar END

//change mat form field height
mat-form-field.mat-form-field.mat-form-field-appearance-outline
  > div.mat-form-field-wrapper
  > div.mat-form-field-flex
  > div.mat-form-field-infix {
  padding: 0.4em 0px;
}

mat-form-field.mat-form-field.mat-form-field-appearance-outline
  > div.mat-form-field-wrapper
  > div.mat-form-field-flex
  > div.mat-form-field-infix
  > span.mat-form-field-label-wrapper {
  top: -1.5em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label {
  transform: translateY(-1em) scale(0.75);
  width: 133.33333%;
}

.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0 !important;
}

.my-form-field .mat-form-field-wrapper {
  padding-bottom: 7px !important;
}

.seconizeButton {
  border-radius: 20px !important;
  height: 33px !important;
  line-height: 33px !important;
  font-size: 13px !important;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px !important;

  &.blue {
    color: white;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px !important;
    background-color: #3c90df;
  }

  &.outline {
    box-shadow: none;
    border: solid 1px;
  }

  &.small {
    font-size: 0.8rem;
    line-height: 1.8rem !important;
    padding: 0 9px 0 4px;
    height: 1.8rem !important;

    .mat-icon {
      line-height: 1.3rem !important;
      vertical-align: middle;
      font-size: 1rem;
    }
  }

  &.menu {
    padding: 0 16px 0 5px;
  }

  &.filterButton {
    line-height: 20px !important;
    padding: 0px 10px !important;
    height: 22px !important;
    margin-bottom: 3px;
    font-size: 0.75rem !important;

    .mat-icon {
      line-height: 19px !important;
      height: 21px !important;
      font-size: 1rem !important;
      width: 15px;
    }
  }
}

.content {
  margin: 41px 0 32px;
  padding: 0 16px;
}

.seconizeInput {
  width: 19rem !important;
  background-color: #fff;
  border-radius: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px !important;
  padding: 2px 10px;

  input {
    border: none;
    width: 30rem !important;
    line-height: 30px !important;
    font-size: 12px;
    border-radius: 20px !important;

    &:focus {
      outline: none;
    }
  }
}

.search {
  color: black;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

// slider
.mat-slider-horizontal {
  height: 32px !important;
}

.mat-slider {
  padding: 0px !important;
  vertical-align: top !important;
}

// mat tab

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 10px;
}

.cardTitle {
  color: #0072b1 !important;
  font-weight: 500 !important;
  font-size: 18px !important;

  &.small {
    font-size: 15px !important;
  }

  &.nomargin {
    margin: 0 !important;
  }
}

.mat-header-cell {
  font-weight: bold !important;
}

td.mat-cell {
  padding: 16px;
}

//////////////////////////////GENERIC/////////////////////

.cursor-pointer {
  cursor: pointer;
  position: relative;
  bottom: 7px;
}

// SEVERITY COLORS
.CRITICAL {
  background-color: #bf1f2ac9;
}

.HIGH {
  background-color: #f36a26e3;
}

.MEDIUM {
  background-color: #e7ba15e5;
}

.LOW {
  background-color: #72c1e1e8;
}

.VERY_LOW {
  background-color: #bebebee8;
}

.center {
  text-align: center !important;
}

.right {
  float: right !important;
}

.left {
  float: left !important;
}

// WIDTH
.w-100 {
  width: 100% !important;
}
.w-90 {
  width: 90% !important;
}

.w-86 {
  width: 86%;
}

.w-85 {
  width: 85% !important;
}

.w-80 {
  width: 80% !important;
}

.w-75 {
  width: 75% !important;
}
.w-74 {
  width: 75% !important;
}

.mt-10px {
  margin-top: 10px;
}
.wb-ba {
  word-break: break-all;
}

// minimum height
.min-h-10 {
  min-height: 10vh !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mb-20px {
  margin-bottom: 20px;
}
.mr-7px {
  margin-right: 7px;
}
.mb-10px {
  margin-bottom: 10px;
}

.overflowauto {
  overflow: auto;
}
.transform {
  transform: scale(0.8);
}

// FUNCTION TO SET TABLE COLUMN WIDTH DYNAMICALLY
@mixin mat-table-columns($columns) {
  .mat-column- {
    @each $colName, $props in $columns {
      $width: map-get($props, "width");

      &#{$colName} {
        flex: $width;
        width: $width;

        @if map-has-key($props, "padding") {
          padding: map-get($props, "padding") !important;
        }

        @if map-has-key($props, "color") {
          color: map-get($props, "color");
        }
      }
    }
  }
}
// @media screen and (max-width: 799px) {
//   table {
//     @include mat-table-columns(
//       (
//         cve: (
//           width: 22%,
//           padding: 0px 5px 0px 5px,
//         ),
//         cvss: (
//           padding: 0px 5px 0px 0px,
//         ),
//         riskScore: (
//           padding: 0px 5px 0px 5px,
//         ),
//         malware: (
//           padding: 0px 5px 0px 5px,
//         ),
//         exploit: (
//           padding: 0px 5px 0px 5px,
//         ),
//         assetReachability: (
//           padding: 0px 5px 0px 0px,
//         ),
//         threatScore: (
//           padding: 0px 5px 0px 0px,
//         ),
//         dataRating: (
//           padding: 0px 5px 0px 0px,
//         ),
//         serviceRating: (
//           padding: 0px 5px 0px 0px,
//         ),
//         impactScore: (
//           padding: 0px 5px 0px 0px,
//         ),
//       )
//     );
//   }
// }
.page-container {
  background-image: linear-gradient(to right, #3c90df, #30d093);
  opacity: 0.9;
}
.content-wrapper {
  background-color: #fff;
  min-height: 90vh;
}

.content-container {
  margin-bottom: 5px !important;
  border-top: 5px solid transparent !important;
}

.success-snackbar {
  color: beige !important;
  background-color: #48ad4e !important;
}

.error-snackbar {
  color: beige !important;
  background-color: #c62828 !important;
}

.copy-snackbar {
  color: black !important;
  background-color: whitesmoke !important;
}
.error-emoji {
  font-size: 5rem;
  height: 6rem;
  line-height: 6rem;
  margin-bottom: 25px;

  &.small {
    margin-bottom: 0px;
    font-size: 3rem;
  }
}